<template>
  <div class="form-container">
    <div class="form-wrapper">
      <div class="form__title">
        <slot name="title">
          <h2>{{ title }}</h2>
        </slot>
      </div>
      <form @submit.prevent="$emit('submit-form')">
        <div class="form__inputs">
          <slot name="inputs" />
        </div>
        <div class="form__actions">
          <slot name="actions" />
        </div>
      </form>

      <div class="form__footer">
        <transition name="fade">
          <div class="form__footer__error" v-if="hasError">
            <slot name="error" />
          </div>
        </transition>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthContainer",
  props: {
    title: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.form-container {
  background-color: $light-gray;
  padding: 48px;
  max-width: 70%;

  .form {
    &__title {
      margin-bottom: 48px;
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 71px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-align: center;
      }
    }
    &__header {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
    &__logo {
      width: 96px;
    }

    &__inputs {
      margin-bottom: 48px;
    }
  }
}
</style>
