<template>
  <div v-if="loginSuccess" style="margin: 100px auto 0">
    <h1 class="pb-5" style="text-align: center">
      You have successfully logged in
    </h1>
    <img
      src="@/assets/img/ac-logo.png"
      alt="ArcticCon logo"
      style="width: 100%"
      class="px-6"
    />
  </div>
  <AuthContainer
    v-else
    title="Create new password"
    :no-shrink-heading="false"
    :has-error="forgotError"
    @submit-form="createNewPassword"
    :loading="isFormSending"
    class="form-container"
  >
    <template #inputs>
      <Input
        v-for="(field, name) in form"
        :key="name"
        v-model="field.value"
        :error="field.error"
        :name="name"
        :label="field.label"
        :type="name.toLowerCase().includes('password') ? 'password' : 'text'"
        @input="clearError(name, form)"
        @blur="validateField(name, form)"
      />
      <Input
        v-model="helperForm.confirmPassword.value"
        :error="helperForm.confirmPassword.error"
        name="confirmPassword"
        label="Confirm password"
        type="password"
        @input="clearError('confirmPassword', helperForm)"
        @blur="validateField('confirmPassword', helperForm)"
      />
    </template>
    <template #actions>
      <div class="actions">
        <Button @click="createNewPassword">Confirm</Button>
      </div>
    </template>
    <template #error>
      <Error>
        <template #heading>
          {{
            errorMsg ||
            "Oops! It seems that an issue occured while changing your password."
          }}
        </template>
        <template #body>
          If this issue continues, contact us at
          <a href="mailto:info@arctic-con.com" class="error__link">
            info@arctic-con.com
          </a>
        </template>
      </Error>
    </template>
    <template #text>
      Password must be 8 characters long and contain an uppercase, lowercase,
      digit, and special character
    </template>
  </AuthContainer>
</template>

<script>
import { mapActions } from "vuex";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import { transformForm } from "@/assets/js/utils";
import AuthContainer from "../AuthContainer";
import Input from "@/components/common/Input";
import Error from "@/components/common/Error";
import Button from "@/components/common/Button";

export default {
  name: "NewPassword",
  components: {
    AuthContainer,
    Input,
    Error,
    Button,
  },
  data: () => ({
    isFormSending: false,
    forgotError: false,
    errorMsg: "",
    form: {
      password: {
        value: "",
        error: "",
        rules: ["required", "password"],
        label: "Password",
      },
    },
    helperForm: {
      confirmPassword: {
        value: "",
        error: "",
        rules: ["required", "password"],
      },
    },
    loginSuccess: false,
  }),
  computed: {},
  methods: {
    ...mapActions(["forgotPasswordSubmit", "signIn"]),
    transformForm,
    validateField,
    validateForm,
    clearError,
    createNewPassword() {
      const isValid = this.validateForm(this.form);
      if (!isValid) return;

      if (this.form.password.value !== this.helperForm.confirmPassword.value) {
        this.helperForm.confirmPassword.error = "Passwords do not match";
        return;
      }
      this.isFormSending = true;
      let data = this.transformForm(this.form);
      data.username = this.$route.params.userName;
      data.code = this.$route.params.code;

      this.forgotPasswordSubmit(data)
        .then(() => {
          this.signIn(data)
            .then(() => {
              this.loginSuccess = true;
              console.log("you already logged in");
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          this.forgotError = true;
          if (err.message) this.errorMsg = err.message;
          this.forgotError = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  margin: 300px auto;
}
.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
